import { getNElementsFrom } from '@mv-landers/common/lib/utils/random';
import GetStartedButton from '../GetStartedButton/GetStartedButton.vue';
import { starsList } from './data';

const MAX_STARS_NUM = 16;

export default {
  name: 'MvStarsSection',
  components: {
    GetStartedButton,
  },
  data() {
    return {
      stars: getNElementsFrom(MAX_STARS_NUM, starsList),
    };
  },
};
