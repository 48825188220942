export const PLUS_ICON_SVG = `
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
    <path data-name="Plus Icon" d="M15.226,7.226H8.774V.774a.774.774,0,0,0-1.548,0V7.226H.774a.774.774,0,0,0,0,1.548H7.226v6.452a.774.774,0,0,0,1.548,0V8.774h6.452a.774.774,0,0,0,0-1.548" fill="#fff"/>
  </svg>
`;

export const MINUS_ICON_SVG = `
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
    <g data-name="Minus Icon" transform="translate(-140 -934)">
      <path id="Path_16334" data-name="Path 16334" d="M15.226,7.226H.774a.774.774,0,0,0,0,1.548H15.226a.774.774,0,0,0,0-1.548" transform="translate(140 933.774)" fill="#fff"/>
      <rect id="Rectangle_11739" data-name="Rectangle 11739" width="16" height="16" transform="translate(140 934)" fill="#fff" opacity="0"/>
    </g>
  </svg>
`;
