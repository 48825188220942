import {
  VueCollapsiblePanelGroup,
  VueCollapsiblePanel,
} from '@dafcoe/vue-collapsible-panel';
import { PLUS_ICON_SVG, MINUS_ICON_SVG } from './assets/svg-icons';
import { faqs } from './data';

const toggledStatuses = [false, false, false, false, false];

export default {
  name: 'FQASection',
  components: {
    VueCollapsiblePanelGroup,
    VueCollapsiblePanel,
  },
  mounted() {
    const toggleIcons = Array.from(document.getElementsByClassName('vcp__header-icon'));

    toggleIcons.forEach((_, i) => {
      toggleIcons[i].children[0].innerHTML = PLUS_ICON_SVG;
    });
  },
  data() {
    return {
      faqs,
    };
  },
  methods: {
    togglePanel: (i) => {
      const icon = document.getElementById(`togglePanel-${i}`).getElementsByClassName('vcp__header-icon')[0].children[0];

      if (toggledStatuses[i]) {
        /* eslint-disable no-param-reassign */
        icon.innerHTML = PLUS_ICON_SVG;
      } else {
        /* eslint-disable no-param-reassign */
        icon.innerHTML = MINUS_ICON_SVG;
      }

      toggledStatuses[i] = !toggledStatuses[i];
    },
  },
};
