const picturePath = '/assets/img/stars';

export const starsList = [
  {
    nickname: 'Britishcandyx',
    picture: `${picturePath}/Britishcandyx.png`,
    link: 'https://track.vipfanclub.com/click/1',
    picsNum: '126',
    videosNum: '8',
  },
  {
    nickname: 'Codi Vore',
    picture: `${picturePath}/Codi-Vore.png`,
    link: 'https://track.vipfanclub.com/click/2',
    picsNum: '111',
    videosNum: '9',
  },
  {
    nickname: 'Kymberly',
    picture: `${picturePath}/Kymberly.png`,
    link: 'https://track.vipfanclub.com/click/3',
    picsNum: '300',
    videosNum: '45',
  },
  {
    nickname: 'PeachySkye',
    picture: `${picturePath}/PeachySkye.png`,
    link: 'https://track.vipfanclub.com/click/4',
    picsNum: '895',
    videosNum: '96',
  },
  {
    nickname: 'Tatum Christine',
    picture: `${picturePath}/Tatum-Christine.png`,
    link: 'https://track.vipfanclub.com/click/5',
    picsNum: '99',
    videosNum: '41',
  },
  {
    nickname: 'ImMeganLive',
    picture: `${picturePath}/ImMeganLive.png`,
    link: 'https://track.vipfanclub.com/click/6',
    picsNum: '901',
    videosNum: '1.3K',
  },
  {
    nickname: 'purple_bitch',
    picture: `${picturePath}/purple_bitch.png`,
    link: 'https://track.vipfanclub.com/click/7',
    picsNum: '667',
    videosNum: '575',
  },
  {
    nickname: 'Destinationkat',
    picture: `${picturePath}/Destinationkat.png`,
    link: 'https://track.vipfanclub.com/click/8',
    picsNum: '1.4K',
    videosNum: '264',
  },
  {
    nickname: 'Natasha Nixx',
    picture: `${picturePath}/Natasha-Nixx.png`,
    link: 'https://track.vipfanclub.com/click/9',
    picsNum: '89',
    videosNum: '14',
  },
  {
    nickname: 'CharlotteMae',
    picture: `${picturePath}/CharlotteMae.png`,
    link: 'https://track.vipfanclub.com/click/10',
    picsNum: '438',
    videosNum: '188',
  },
  {
    nickname: 'Amber_Hallibell',
    picture: `${picturePath}/Amber-Hallibell.png`,
    link: 'https://track.vipfanclub.com/click/11',
    picsNum: '809',
    videosNum: '365',
  },
  {
    nickname: 'Octokuro',
    picture: `${picturePath}/Octokuro.png`,
    link: 'https://track.vipfanclub.com/click/12',
    picsNum: '247',
    videosNum: '40',
  },
  {
    nickname: 'Korina Kova',
    picture: `${picturePath}/Korina-Kova.png`,
    link: 'https://track.vipfanclub.com/click/13',
    picsNum: '469',
    videosNum: '213',
  },
  {
    nickname: 'Brea Rose',
    picture: `${picturePath}/Brea-Rose.png`,
    link: 'https://track.vipfanclub.com/click/14',
    picsNum: '3.8K',
    videosNum: '155',
  },
  {
    nickname: 'MillieMillz',
    picture: `${picturePath}/MillieMillz.png`,
    link: 'https://track.vipfanclub.com/click/15',
    picsNum: '601',
    videosNum: '29',
  },
  {
    nickname: 'MollyRedWolf',
    picture: `${picturePath}/MollyRedWolf.png`,
    link: 'https://track.vipfanclub.com/click/16',
    picsNum: '510',
    videosNum: '48',
  },
  {
    nickname: 'phatassedangel69',
    picture: `${picturePath}/phatassedangel69.png`,
    link: 'https://track.vipfanclub.com/click/17',
    picsNum: '106',
    videosNum: '54',
  },
  {
    nickname: 'lilcanadiangirl',
    picture: `${picturePath}/lilcanadiangirl.png`,
    link: 'https://track.vipfanclub.com/click/18',
    picsNum: '1.6K',
    videosNum: '102',
  },
  {
    nickname: 'nicoledoshi',
    picture: `${picturePath}/nicoledoshi.png`,
    link: 'https://track.vipfanclub.com/click/19',
    picsNum: '551',
    videosNum: '648',
  },
  {
    nickname: 'Sydney Harwin',
    picture: `${picturePath}/Sydney-Harwin.png`,
    link: 'https://track.vipfanclub.com/click/20',
    picsNum: '1.5K',
    videosNum: '44',
  },
  {
    nickname: 'Scarlet Chase',
    picture: `${picturePath}/Scarlet-Chase.png`,
    link: 'https://track.vipfanclub.com/click/21',
    picsNum: '558',
    videosNum: '22',
  },
  {
    nickname: 'Jessiheat',
    picture: `${picturePath}/Jessiheat.png`,
    link: 'https://track.vipfanclub.com/click/22',
    picsNum: '410',
    videosNum: '22',
  },
  {
    nickname: 'Nicole Nabors',
    picture: `${picturePath}/Nicole-Nabors.png`,
    link: 'https://track.vipfanclub.com/click/23',
    picsNum: '508',
    videosNum: '445',
  },
  {
    nickname: 'Ariana Aimes',
    picture: `${picturePath}/Ariana-Aimes.png`,
    link: 'https://track.vipfanclub.com/click/24',
    picsNum: '93',
    videosNum: '47',
  },
  {
    nickname: 'Goddess_Sunshine',
    picture: `${picturePath}/Goddess-Sunshine.png`,
    link: 'https://track.vipfanclub.com/click/25',
    picsNum: '89',
    videosNum: '216',
  },
  {
    nickname: 'Camran Mac',
    picture: `${picturePath}/Camran-Mac.png`,
    link: 'https://track.vipfanclub.com/click/26',
    picsNum: '274',
    videosNum: '325',
  },
  {
    nickname: 'Little Puck',
    picture: `${picturePath}/Little-Puck.png`,
    link: 'https://track.vipfanclub.com/click/27',
    picsNum: '709',
    videosNum: '285',
  },
  {
    nickname: 'Annabelle Rogers',
    picture: `${picturePath}/Annabelle-Rogers.png`,
    link: 'https://track.vipfanclub.com/click/28',
    picsNum: '3.3K',
    videosNum: '238',
  },
  {
    nickname: 'Emmas Secret Life',
    picture: `${picturePath}/Emmas-Secret-Life.png`,
    link: 'https://track.vipfanclub.com/click/29',
    picsNum: '390',
    videosNum: '202',
  },
  {
    nickname: 'Lana Rain',
    picture: `${picturePath}/Lana-Rain.png`,
    link: 'https://track.vipfanclub.com/click/30',
    picsNum: '65',
    videosNum: '168',
  },
  {
    nickname: 'sloansmoans',
    picture: `${picturePath}/sloansmoans.png`,
    link: 'https://track.vipfanclub.com/click/31',
    picsNum: '100',
    videosNum: '39',
  },
  {
    nickname: 'Aiko Moe',
    picture: `${picturePath}/Aiko-Moe.png`,
    link: 'https://track.vipfanclub.com/click/32',
    picsNum: '824',
    videosNum: '129',
  },
  {
    nickname: 'Xev Bellringer',
    picture: `${picturePath}/Xev-Bellringer.png`,
    link: 'https://track.vipfanclub.com/click/33',
    picsNum: '93',
    videosNum: '31',
  },
  {
    nickname: 'NiaHarris',
    picture: `${picturePath}/NiaHarris.png`,
    link: 'https://track.vipfanclub.com/click/34',
    picsNum: '303',
    videosNum: '142',
  },
  {
    nickname: 'Lanie Love',
    picture: `${picturePath}/Lanie-Love.png`,
    link: 'https://track.vipfanclub.com/click/35',
    picsNum: '3K',
    videosNum: '167',
  },
  {
    nickname: '2bunnyboys',
    picture: `${picturePath}/2bunnyboys.png`,
    link: 'https://track.vipfanclub.com/click/36',
    picsNum: '32',
    videosNum: '47',
  },
  {
    nickname: 'TheRoyalKitty',
    picture: `${picturePath}/TheRoyalKitty.png`,
    link: 'https://track.vipfanclub.com/click/37',
    picsNum: '66',
    videosNum: '55',
  },
  {
    nickname: 'Scarlett Bloom',
    picture: `${picturePath}/Scarlett-Bloom.png`,
    link: 'https://track.vipfanclub.com/click/38',
    picsNum: '157',
    videosNum: '53',
  },
];

export default { starsList };
