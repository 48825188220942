// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./assets/img/background.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "html,\nbody,\nh1,\nh2,\nh3,\nh4,\nh5,\nh6,\ndiv,\np,\n.app-container {\n    box-sizing: border-box;\n    margin: 0;\n    padding: 0;\n}\nhtml {\n    scroll-behavior: smooth;\n}\nbody {\n    width: 100%;\n    background-color: #000000;\n    overflow-x: hidden;\n}\n.app-container {\n    width: 100vw;\n    height: 100%;\n    background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n    background-color: #000000;\n    background-position: center top;\n    overflow-x: hidden;\n    background-repeat: repeat-y;\n    background-attachment: fixed;\n    margin-left: auto;\n    margin-right: auto;\n    background-size: auto;\n    position: fixed;\n    top: 0;\n    z-index: -1;\n}\n.top-container {\n    width: 100%;\n    height: 720px;\n    background: transparent linear-gradient(90deg, #000000 0%, #00000000 100%) 0% 0% no-repeat padding-box;\n    text-align: center;\n    opacity: 1;\n}\n.fadeout-divider {\n    position: relative;\n    margin-top: -100px;\n    width: 100%;\n    height: 100px;\n    background: transparent linear-gradient(180deg, #00000000 0%, #000000 100%) 0% 0% no-repeat padding-box;\n    opacity: 1;\n}\n.centered {\n    margin-left: auto;\n    margin-right: auto;\n}\n.left-col,\n.right-col {\n    vertical-align: top;\n    display: inline-block;\n}\n.v-space-80 {\n    height: 80px;\n}\n@media (max-width: 810px) {\n.top-container {\n        text-align: center !important;\n}\n.logo-subtitle {\n        margin: -18px 0 0 4px;\n}\n}\n", ""]);
// Exports
module.exports = exports;
