import MvLogo from './components/MvLogo/MvLogo.vue';
import ConnectSection from './components/ConnectSection/ConnectSection.vue';
import MvStarsSection from './components/MvStarsSection/MvStarsSection.vue';
import PostsSection from './components/PostsSection/PostsSection.vue';
import FAQSection from './components/FAQSection/FAQSection.vue';
import PreFooterSection from './components/PreFooterSection/PreFooterSection.vue';
import FooterSection from './components/FooterSection/FooterSection.vue';

export default {
  name: 'App',
  components: {
    MvLogo,
    ConnectSection,
    MvStarsSection,
    PostsSection,
    FAQSection,
    PreFooterSection,
    FooterSection,
  },
};
