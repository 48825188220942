<div class="mv-stars-list-container">
    <h2>A DIVERSE COMMUNITY</h2>
    <h3>Over 30,000+ Adult Content Creators</h3>
    <p>Subscribe to one or more of your favorite creators and get exciting, never before seen exclusive content.</p>
    <div class="stars-list">
        <div class="mv-star" v-for="star in stars">
            <a :href="star.link">
                <img class="picture" :src="star.picture" :alt="star.nickname" />
                <div class="star-card">
                    <h4>{{ star.nickname }}</h4>
                    <div class="num-pic-videos">
                        <img src="../../assets/img/image-icon.svg" alt="Image Icon" />
                        <span>{{ star.picsNum }}</span>
                        <img src="../../assets/img/video-icon.svg" alt="Video Icon" />
                        <span>{{ star.videosNum }}</span>
                    </div>
                </div>
            </a>
        </div>
    </div>
    <get-started-button class="centered margin-bottom-80"  link="https://track.vipfanclub.com/click/40" />
    <div class="v-space-80"></div>
</div>
