<div>
    <div class="faq-container">
        <h2>FREQUENTLY ASKED QUESTIONS</h2>
        <div class="faq-list">
            <vue-collapsible-panel-group>
                <vue-collapsible-panel :key="'togglePanel-' + index" :id="'togglePanel-' + index" class="faq-item" v-for="(faq, index) in faqs"
                    :expanded="false" @click="togglePanel(index)">
                    <template #title>
                        <h3>{{ faq.question }}</h3>
                    </template>
                    <template #content>
                        {{ faq.answer }}
                    </template>
                </vue-collapsible-panel>
            </vue-collapsible-panel-group>
        </div>
    </div>
    <div class="fadein-divider"></div>
</div>
