export const faqs = [
  {
    question: 'What is VIP FanClub?',
    answer: `VIP FanClub is a subscription-based service that content creators on ManyVids can
        use to give their fans exclusive access to fan-only vids, pics, and messages. Members get
        exciting, never before seen content from their favorite creators, and it's a great way to
        make connections within the ManyVids Community!`,
  },
  {
    question: 'How much does it cost?',
    answer: `The cost of a subscription to VIP FanClub will differ per content creator who
        offers the service.`,
  },
  {
    question: 'Is there a trial option?',
    answer: `Yes! The trial period option on VIP FanClub is used by content creators who wish
        to offer Members access to their VIP FanClub at a reduced trial rate.`,
  },
  {
    question: 'Can VIP FanClub subscriptions be cancelled at any time?',
    answer: `Yes! From the "Purchase History" section of your account, you can disable the
        rebill for any active VIP FanClub subscription at any time.`,
  },
  {
    question: 'Do you offer customer support?',
    answer: `Yes! Our "MV Members Help Center" should be able to provide information to any
        inquiry you might have. We also have support agents availabe 24/7 to answer your questions
        via email.`,
  },
];

export default { faqs };
