<div class="posts-container">
    <div class="left-col">
        <img class="transparent-post-top" src="./assets/img/transparent-post-top.png" alt="VIP FanClub Post" />
        <img class="main-img" src="./assets/img/posts.png" alt="VIP FanClub Posts" />
        <img  class="transparent-post-bottom" src="./assets/img/transparent-post-bottom.png" alt="VIP FanClub Post" />
    </div>
    <div class="right-col">
        <h2>VIP FanClub Is Better!</h2>
        <p>A thriving Fansite loyal to it's users.</p>
        <div class="items-container">
            <div class="item star-item">
                <img src="./assets/img/star-icon.svg" alt="Stars Icon" />
                <span><b>750+</b> VIP FanClub's added monthly.</span>
            </div>
            <div class="item video-item">
                <img src="./assets/img/video-icon.svg" alt="Stars Icon" />
                <span><b>Over 27,000</b> new exclusive vids added every month.</span>
            </div>
            <div class="item msg-item">
                <img src="./assets/img/message-icon.svg" alt="Stars Icon" />
                <span>Watch, Share, Love and Interact with Content Creators!</span>
            </div>
            <div class="item send-item">
                <img src="./assets/img/send-icon.svg" alt="Stars Icon" />
                <span><b>Over 16,000+</b> posts added everyday across all VIP FanClub's.</span>
            </div>
        </div>
        <get-started-button link="https://track.vipfanclub.com/click/42" />
    </div>
    <div class="clear-end"></div>
</div>
