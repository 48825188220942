<div class="top-container">
  <mv-logo />
  <connect-section />
</div>
<div class="fadeout-divider"></div>
<mv-stars-section />
<posts-section />
<f-a-q-section />
<pre-footer-section />
<footer-section />
